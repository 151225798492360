import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <video
          autoPlay
          muted
          style={{
            maxWidth: '60%',  // Adjust width
            height: 'auto',   // Maintain aspect ratio
            borderRadius: '10px',  // Optional: Add some border radius
          }}
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/concept-9-ea773.appspot.com/o/Black%20Photo%20Coming%20Soon%20Instagram%20Post-vmake%20(1).mp4?alt=media&token=5af67363-acd2-4ea0-92ea-e6c1b4f64852"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </header>
      <footer className="App-footer">
        <a href="mailto:info@concept9.in" className="email">Email: info@concept9.in</a>
        <br />
        <p>Connect with us:</p>
        <br />
        <div className="social-links">
          <a href="https://www.facebook.com/profile.php?id=61564861414907&sk=about" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://x.com/TheConcept_9" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-x-twitter"></i>
          </a>
          <a href="https://www.instagram.com/theconcept9/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/theconcept9/about" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;

